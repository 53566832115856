import { MessageContext, chatState } from '@/service/chat';
import { supabase } from '@/utils/supabase-client';
import getConfig from 'next/config';
import { useEffect } from 'react';
const { publicRuntimeConfig } = getConfig();

const compareColors = [
  '#5fe625',
  '#0398fc',
  '#03fcfc',
  '#03fc03',
  '#E50914',
  '#fc03a3',
  '#03fcfc',
  '#fcfc03',
  '#03a3fc'
];

export function useChatQuestion(user) {
  useEffect(() => {
    if (!user) {
      return;
    }

    supabase
      .from('msh_chat_sessions')
      .select('*')
      // .select('*, msh_chat_messages(*)')
      .order('updated_at', { ascending: false })
      // .eq('user_id', user.id)
      // .order('created_at', {
      //   ascending: false,
      //   foreignTable: 'msh_chat_messages'
      // })
      // .is('msh_chat_messageshidden_at', null)
      .limit(15)
      // .limit(15, { foreignTable: 'msh_chat_messages' })

      .then((res) => {
        if (res.data && res.data.length > 0) {
          chatState.sessions = res.data;
          // chatState.messages = [res.data[0].msh_chat_messages || []]
          //   .flat()
          //   .reverse();
          chatState.activeSession = res.data[0];
          chatState.sessionId = res.data[0].id;

          switchContext(res.data[0].id);
        }
      });
  }, [user]);

  const doSubmit = async (msg: string) => {
    chatState.loading = true;
    chatState.openRequest = new Date().valueOf();
    if (!user?.id) {
      chatState.loading = false;
      return;
    }
    if (!chatState.sessionId) {
      await createNewContext();
    }
    const { data, error } = await supabase
      .from('msh_chat_messages')
      .insert({
        chat_session_id: chatState.sessionId as string,
        message: msg,
        anon_session_id: chatState.sessionId,
        is_response: false
      })
      .select()
      .single();
    // const session_id = localStorage?.getItem('session_id');
    chatState.prompt = '';
    chatState.currentContext.req_start = Date.now();
    chatState.currentId++;

    // fetch(`/api/chat`, {
    //   method: 'POST',
    //   headers: {
    //     'x-api-key': user?.api_token,
    //     'Content-Type': 'application/json'
    //   },

    //   body: JSON.stringify({
    //     query: msg,
    //     session_id: session_id,
    //     context_msh_id: chatState?.context_msh_id,
    //     context_cluster_uuid: chatState?.context_cluster_uuid,
    //     chat_session_id: chatState.sessionId
    //   })
    // })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     if (data?.error && data?.error == 'Not authenticated') {
    //       chatState.loading = false;
    //       chatState.remaining_credits = 0;
    //       localStorage?.setItem('remaining_credits', '0');
    //       return;
    //     }
    //     if (!user) {
    //       chatState.loading = false;
    //     }
    //   })
    //   .catch((e) => {
    //     console.log('error', e);
    //   });
  };

  const loadUserProfiles = async (userIds: string[]) => {
    const missingUserIds = userIds.filter(
      (id) => !chatState.userProfileLookup[id]
    );

    if (missingUserIds.length > 0) {
      const { data: profiles, error } = await supabase
        .from('msh_user_profile')
        .select('user_id, username, name, avatar_url')
        .in('user_id', missingUserIds);

      if (profiles) {
        profiles.forEach((profile) => {
          chatState.userProfileLookup[profile.user_id] = profile;
        });
      }
    }
  };

  const switchContext = (id: string) => {
    const session = chatState.sessions.find((s) => s.id == id);
    if (!session) {
      return;
    }
    chatState.activeSession = session;
    chatState.messages = []; //session.msh_chat_messages.reverse();
    chatState.sessionId = session.id;
    supabase
      .from('msh_chat_sessions')
      .update({ has_new_message: null })
      .eq('id', id)
      .then((res) => {
        console.log('res', res);
      });
    supabase
      .from('msh_chat_messages')
      .select('*')
      .eq('chat_session_id', id)
      .order('created_at', { ascending: false })
      .limit(15)
      .then((res) => {
        if (res.data) {
          chatState.messages = res.data?.reverse() || [];
        }
        console.log('messages: ', chatState.messages);

        const userIds = new Set(
          chatState.messages.map((m) => m.user_id).filter(Boolean)
        );
        console.log('extracted user id', userIds);

        loadUserProfiles([...userIds]);
      });
    // Create a set of unique user IDs from the messages
  };

  const createNewContext = async () => {
    return supabase
      .from('msh_chat_sessions')
      .insert({})
      .select('*, msh_chat_messages(*)')
      .single()
      .then((res) => {
        if (res.data && res.data.id != null) {
          chatState.sessionId = res.data.id;
          chatState.activeSession = res.data;
          chatState.sessions.unshift(res.data);
          chatState.messages = [res?.data?.msh_chat_messages || []].flat();
        }
      });
  };

  return { doSubmit, createNewContext, switchContext };
}
