import { Tables } from '@/types/supabase';
import { supabase } from '@/utils/supabase-client';
import { QueryData, RealtimeChannel } from '@supabase/supabase-js';
import { proxy, useSnapshot } from 'valtio';

const listWithSymbolsQuery = supabase
  .from('msh_lists')
  .select(
    '*,msh_lists_symbols(msh_id,msh_symbol_list_id,status,added_by,status_detail)'
  )
  .single();
type ListsWithSymbols = QueryData<typeof listWithSymbolsQuery>;

const store = proxy({
  loading: true,
  items: [] as ListsWithSymbols[],
  subscriptions: {
    enabled: false,
    channels: [] as RealtimeChannel[]
  },

  enableSubscriptions: async () => {
    if (store.subscriptions.enabled) return;

    // Subscribe to lists changes
    const listsChannel = supabase
      .channel('lists-changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'msh_lists'
        },
        (payload) => store.handleRealtimeUpdate(payload as any)
      )
      .subscribe();

    // Subscribe to symbols changes
    const symbolsChannel = supabase
      .channel('symbols-changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'msh_lists_symbols'
        },
        (payload) => store.handleRealtimeSymbol(payload as any)
      )
      .subscribe();

    store.subscriptions.channels.push(listsChannel, symbolsChannel);
    store.subscriptions.enabled = true;
  },

  disableSubscriptions: async () => {
    if (!store.subscriptions.enabled) return;

    // Unsubscribe from all channels
    await Promise.all(
      store.subscriptions.channels.map((channel) => channel?.unsubscribe())
    );

    store.subscriptions.channels = [];
    store.subscriptions.enabled = false;
  },

  addTicker: async (id, ticker) => {
    const existingItem = store.getItemById(id);
    if (!existingItem) {
      console.log('List not found');
      return;
    }

    // Ensure msh_lists_symbols is initialized
    if (!existingItem.msh_lists_symbols) {
      existingItem.msh_lists_symbols = [];
    }

    // Check if the ticker already exists in the list
    if (
      existingItem.msh_lists_symbols.some((symbol) => symbol.msh_id === ticker)
    ) {
      console.log('Ticker already exists in the list');
      return;
    }

    const { data, error } = await supabase
      .from('msh_lists_symbols')
      .upsert(
        {
          msh_id: ticker,
          msh_symbol_list_id: id as string,
          status: 'done',
          deleted_at: null
        },
        { onConflict: 'msh_id,msh_symbol_list_id' }
      )
      .select('*,msh_quotes_realtime(source_ref)');
    if (error) {
      console.error('error', error);
      return;
    }
    const item = data?.[0];
    if (item) {
      existingItem.msh_lists_symbols.push(item);
    }
  },

  removeTicker: async (id, ticker) => {
    // console.log('remove ticker', id, ticker);
    if (!id || !ticker) {
      return;
    }
    const existingItem = store.getItemById(id);
    if (!existingItem || !existingItem.msh_lists_symbols) {
      console.log('List not found or symbols not initialized');
      return;
    }
    supabase
      .from('msh_lists_symbols')
      .delete()
      .eq('msh_id', ticker)
      .then((res) => {
        if (!res.error) {
          console.log('removed symbol');
        }
        existingItem.msh_lists_symbols = existingItem.msh_lists_symbols.filter(
          (stock) => stock.msh_id !== ticker
        );
      });
  },

  loadInitial: async () => {
    console.log('loading initial');
    const { error, data } = await supabase
      .from('msh_lists')
      .select('*,msh_lists_symbols(msh_id,msh_symbol_list_id,added_by,status)')
      .is('deleted_at', null)
      .order('created_at', { ascending: false });
    if (error) {
      console.error('error', error);
    } else {
      store.mergeItems(
        data.map((item) => ({
          ...item,
          msh_lists_symbols: item.msh_lists_symbols || []
        }))
      );
    }
    store.loading = false;
  },

  getItemById(id) {
    return this.items.find((item) => item.id === id);
  },

  delete: async (id) => {
    const { error, data } = await supabase
      .from('msh_lists')
      .update({ deleted_at: new Date().toISOString() })
      .eq('id', id);
    if (error) {
      console.error('error', error);
      return;
    }
    store.items = store.items.filter((item) => item.id !== id);
  },

  update: async (id, updatedData, persist = false) => {
    if (persist) {
      const { error, data } = await supabase
        .from('msh_lists')
        .update(updatedData)
        .eq('id', id);
      if (error) {
        console.error('error', error);
        return;
      }
    }
    store.items = store.items.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          ...updatedData,
          msh_lists_symbols:
            updatedData.msh_lists_symbols || item.msh_lists_symbols || []
        };
      }
      return item;
    });
  },

  setItems(items) {
    this.items = items.map((item) => ({
      ...item,
      msh_lists_symbols: item.msh_lists_symbols || []
    }));
  },

  addItem(item) {
    const existingItemIndex = this.items.findIndex(
      (existingItem) => existingItem.id === item.id
    );
    if (existingItemIndex !== -1) {
      // If item with the same id exists, update it
      this.items[existingItemIndex] = {
        ...this.items[existingItemIndex],
        ...item,
        msh_lists_symbols:
          item.msh_lists_symbols ||
          this.items[existingItemIndex].msh_lists_symbols ||
          []
      };
    } else {
      // If item doesn't exist, add it
      this.items.push({
        ...item,
        msh_lists_symbols: item.msh_lists_symbols || []
      });
    }
  },

  mergeItems(newItems) {
    newItems.forEach((item) => this.addItem(item));
  },

  handleRealtimeUpdate: (payload: {
    eventType: 'INSERT' | 'UPDATE' | 'DELETE';
    new: ListsWithSymbols;
    old: { id: string };
  }) => {
    switch (payload.eventType) {
      case 'INSERT':
        if (!store.items.some((item) => item.id === payload.new.id)) {
          store.addItem({
            ...payload.new,
            msh_lists_symbols: []
          });
        }
        break;
      case 'UPDATE':
        store.update(
          payload.new.id,
          {
            ...payload.new
          },
          false
        );

        break;
      case 'DELETE':
        store.items = store.items.filter((item) => item.id !== payload.old.id);
        break;
    }
  },

  handleRealtimeSymbol: (payload: {
    eventType: 'INSERT' | 'UPDATE' | 'DELETE';
    new: Tables<'msh_lists_symbols'>;
    old: { id: string };
  }) => {
    const listId = payload.new.msh_symbol_list_id;
    const list = store.getItemById(listId);
    if (!list) return;

    // Ensure msh_lists_symbols is initialized
    if (!list.msh_lists_symbols) {
      console.log('Initializing msh_lists_symbols');
      list.msh_lists_symbols = [];
    }

    switch (payload?.eventType) {
      case 'INSERT':
        // if (payload?.new.deleted_at) return;
        // if (payload?.new.status !== 'done') {
        //   return;
        // }

        if (
          !list.msh_lists_symbols.some((symbol) => symbol.id === payload.new.id)
        ) {
          list.msh_lists_symbols.push(payload.new);
        }
        break;
      case 'UPDATE':
        const existingIndex = list.msh_lists_symbols.findIndex(
          (symbol) => symbol.id === payload.new.id
        );

        if (existingIndex !== -1) {
          // Update existing item
          list.msh_lists_symbols[existingIndex] = {
            ...list.msh_lists_symbols[existingIndex],
            ...payload.new
          };
        } else {
          // Add new item
          list.msh_lists_symbols.push(payload.new);
        }
        break;
      case 'DELETE':
        list.msh_lists_symbols = list.msh_lists_symbols.filter(
          (symbol) => symbol.id !== payload.old.id
        );
        break;
    }
  }
});

export function useLists(preloadedList?: Tables<'msh_lists'>[]) {
  const snapshot = useSnapshot(store);

  // If a preloaded list is provided, update the store
  if (preloadedList && preloadedList.length > 0 && store.items.length === 0) {
    store.setItems(preloadedList);
    store.loading = false;
  }

  return {
    ...snapshot,
    enableSubscriptions: store.enableSubscriptions,
    disableSubscriptions: store.disableSubscriptions
  };
}

export default store;
